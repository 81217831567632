import {React, useEffect, useState} from "react";
import ProductInfoCard from "./ProductInfoCard";
import ProductTabs from "./ProductTabs";
import 'react-phone-input-2/lib/style.css'
import {part_list} from "../../api/general";
import {useAuth, useNotification} from "../../hooks";
import FeaturedBlocks from "./FeaturedBlocks";
import {cropText} from "../../hooks/useCustomHelpingMethod";
import {Row} from "react-bootstrap";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import {useParams} from "react-router";
import LastVisitProducts from "../OrderConfirmation/LastVisitProducts";
import SimilarProducts from "../OrderConfirmation/SimilarProducts";
import {appFooterLogo} from "../../api/constant";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";

export default function Main() {
    const [pageTitle, setPageTitle] = useState("Product");
    const [metaKeywords, setMetaKeywords] = useState(null);
    const [metaDescription, setMetaDescription] = useState(null);
    const [metaImageUrl, setMetaImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [part_details, setPart_details] = useState([]);
    const [brand_data, setBrand_data] = useState([]);
    const {slugPart} = useParams();
    const {showError} = useNotification();
    const navigate = useNavigate();

    const {authInfo} = useAuth();

    const getData = async () => {
        // console.log(slugPart);
        setIsLoading(true);
        const {data} = await part_list({
            slug_part: slugPart,
            user_id: authInfo?.profile?.id,
            user_type: authInfo?.profile?.user_type
        });
        console.log(data?.data);
        setPart_details(data?.data);
        if (data?.data) {
            updatePartHistry(data?.data);

            if (data?.data?.title) setPageTitle(data?.data?.title);
            if (data?.data?.url_icon) {
                setMetaImageUrl(data?.data?.url_icon);
            } else {
                if (data?.data?.url_image) setMetaImageUrl(data?.data?.url_icon);
            }
            if (data?.data?.page_keywords) {
                setMetaKeywords(data?.data?.page_keywords);
            } else {
                setMetaKeywords("product, part, " + (data?.data?.title || ""))
            }
            if (data?.data?.page_description) {
                setMetaDescription(data?.data?.page_description);
            } else {
                setMetaDescription(cropText(data?.data?.description || ""))
            }
        } else {
            // window.history.back();
            showError("Product url is not valid");
            navigate('/');
            return;
        }

        const res = await part_list({
            slug_brand: data?.data?.brand_slug,
            slug_brand_true: true,
            user_id: authInfo?.profile?.id,
            user_type: authInfo?.profile?.user_type
        });
        setBrand_data(res?.data?.data)
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, [slugPart]);


    const ArrayFillter = (data, partMain) => {
        if (data) {
            const chek = data.find((element) => element?.slug === partMain?.slug);
            if (chek) return true; else return false;
        } else {
            return true;
        }
    }

    const updatePartHistry = (partObj) => {
        if (partObj && partObj?.slug) {
            let RecentPart = {
                token: partObj?.token,
                slug: partObj?.slug,
                url_icon: partObj?.url_icon,
                title: partObj?.title,
                rate_discount_customer: partObj?.rate_discount_customer,
                amount_total_customer: partObj?.amount_total_customer,

                rate_discount_dealer: partObj?.rate_discount_dealer,
                amount_total_dealer: partObj?.amount_total_dealer,

                setting: partObj?.setting,
                availability: partObj?.availability
            }
            let historySes = localStorage?.getItem('recentVisitsPartHistory');
            const historyJson = historySes ? JSON?.parse(historySes) : [];
            const historyFilter = ArrayFillter(historyJson, partObj);
            if (historySes) {
                if (historyFilter === false) localStorage.setItem('recentVisitsPartHistory', JSON.stringify([...historyJson, RecentPart]));
            } else {
                localStorage.setItem('recentVisitsPartHistory', JSON.stringify([RecentPart]))
            }
        }
    }

    return (
        <div>

            <Helmet>
                <title>{pageTitle || ""}</title>
                <meta name="title" content={pageTitle || ""}/>
                <meta name="keywords" content={metaKeywords || ""}/>
                <meta name="description" content={metaDescription || ""}/>
                <meta name="image" content={metaImageUrl || appFooterLogo}/>
                <meta name="url" content={window.location.href || ""}/>

                <meta property="og:url" content={window.location.href || ""}/>
                {/*<meta property="og:type" content="website"/>*/}
                <meta property="og:title" content={pageTitle || ""}/>
                <meta property="og:description" content={metaDescription || ""}/>
                <meta property="og:image" content={metaImageUrl || appFooterLogo}/>

                {/*<meta name="twitter:title" content={pageTitle || ""}/>*/}
                {/*<meta name="twitter:description" content={metaDescription || ""}/>*/}
                {/*<meta name="twitter:image" content={metaImageUrl || appFooterLogo}/>*/}
                {/*<meta name="twitter:card" content="summary_large_image"/>*/}

            </Helmet>

            {/*<HelmetCustomInclude url={"product/" + slugPart} ref_id={part_details?.id} ref_type={'product_part'}
                title={pageTitle} image={metaImageUrl}
                description={metaDescription}
                keywords={metaKeywords} />*/}
            <section>
                <div className="container py-sm-5 py-2">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            {/* <div className="card-header py-3">
            <h5 className="mb-0">Cart - 2 items</h5>
          </div> */}

                            {isLoading && <SkeletonFileGeneral height={500} total={1} col={12}/>}
                            {!isLoading && <div className="row my-3 info-main">

                                <ProductInfoCard metaDescription={metaDescription} part={part_details}
                                                 id={part_details?.id} title={part_details?.title}
                                                 brand={part_details?.title_brand} model={part_details?.title_model}
                                                 price={part_details?.amount_price} mrp={part_details?.amount_mrp}
                                                 token={part_details?.token_model} stock={part_details?.current_stock}
                                                 img={part_details?.url_images} inCart={part_details?.is_in_user_cart}
                                                 part_type={part_details?.part_type}/>
                            </div>}
                            {isLoading && <Row><SkeletonFileGeneral height={50} total={1} col={12}/></Row>}
                            {isLoading && <Row><SkeletonFileGeneral height={150} total={1} col={12}/></Row>}
                            {isLoading && <Row><SkeletonFileGeneral height={30} total={1} col={3}/></Row>}
                            {/* {isLoading && <Row><SkeletonFileGeneral height={200} total={4} col={3}/></Row>} */}
                            {!isLoading && <ProductTabs data={part_details}/>}

                            {!isLoading && <SimilarProducts colLg={4} colMd={3} colSm={2} data={brand_data}
                                                            brand_id={part_details?.brand_id}
                                                            model_id={part_details?.model_id}/>}
                        </div>
                        <div className="col-md-4">
                            {isLoading && <Row><SkeletonFileGeneral height={500} total={1} col={12}/></Row>}
                            {!isLoading && <FeaturedBlocks/>}
                            {isLoading && <Row><SkeletonFileGeneral height={200} total={1} col={12}/></Row>}

                            {/* {!isLoading && <SimilarProducts data={brand_data} brand_id={part_details?.brand_id}
                                model_id={part_details?.model_id} />} */}
                            {!isLoading && <LastVisitProducts data={part_details}/>}

                        </div>

                    </div>

                </div>
            </section>
        </div>
    );
}
