import client from "./client";
import axios from "axios";

const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYjQxYjcyN2VkOTY5NGMzODQ1ZjM3ZDFmMTkwMjAyYzMxODY4YmU2MTc3OGI2ZjJlOGMwNjY2MzZkZjhhMGE5NzE0ZWE4ZGE2MTBlMjkxYWMiLCJpYXQiOjE2Nzc4MjI2MTEuMTk0MDYxLCJuYmYiOjE2Nzc4MjI2MTEuMTk0MDcsImV4cCI6MzMyMzQ3MzE0MTEuMTYwNjYsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.zTZvF547MhKLOm9sAfqs1Y5hRTpSziPc8TEAV_N_XnukXUoi3KxBHLl6J2avQm2vw_IvIw9Wmvjun8jZjF2xxRICx157cL12lw7TMruNqkynWhZ5k0V_FnP6KrcUO9mTOMR9gNj_mASpHDeMqFeFIJChPWxvd_1lslPaJ91om5FHFnhm8OzzRZXQweSFM9J0UO5t4UfixkWoG0xatubyeFkVp4GsYusoG4UGYFSGCj5aM2KqPmg6LvMIh6skJraV9ufSdj7mWMXCLoA8feSl40bvpHttfdaI709gb3ajyXMtksKt6fGILD8_kESxvP4N1HYdMGO49M2y8ASOHBV5d7OPzYl0PoiTtWSTnmhiJbflFsbczI8OGfYG0cubb8nSQDxRJQ51QReIwO7UCuxnEnDG8Gz7IKOg8ahnarcmBrW2zxr0hM1UmOeAsry9gB-Q8RABcB7fUPBMZN45r6oJqF5inr6qYKouS8_sd78ebUY-jAyYvSFyCL_uZ3lTDic4qXTtICf981GwSa6uGKcdR8UYrq0xbiU0N7RAAua2TF0aVfA6waMT8aCfjuvQA-ypk64YyydkfPr8QOUrqByuNA6OWgkkcdsKSUrD8V6dJvg3ZLEukS_lOTi5SZcvIpjaIQnVKpCgNcLVyqEz-e7ENEr81F0zepDfROlxCeehCpg";
const requiredParams = {
    device_type: "web",
    istoken: "ISABCDEFGH",
    device_token: localStorage.getItem('device_token') || "ABCDEFGH",
    // login_user_id: localStorage.getItem('id') || 0,
    // login_user_type: localStorage.getItem('user_type') || "client",
}
const requiredHeader = {
    Authorization: "Bearer " + authToken,
    accept: "application/json",
    AccessControlAllowOrigin: "*"
}
const requiredHeaderImage = {
    Authorization: "Bearer " + authToken,
    accept: "application/json",
    contentType: "multipart/form-data",
    AccessControlAllowOrigin: "*"
}

export const sheet_service = async ({
                                        category_id,
                                        brand_id,
                                        model_id,
                                        id,
                                        user_type,
                                        device_serial_imei,
                                        token_order,
                                        step,
                                        expected_visit_date,
                                        expected_visit_time
                                    }) => {

    const details = {
        login_user_id: id,
        login_user_type: user_type || "dealer",
        token_order: token_order,
        step: "device-info",
        category_id: category_id,
        brand_id: brand_id,
        model_id: model_id,
        device_serial_imei: device_serial_imei,
        device_serial: device_serial_imei,
    }

    // console.log({...requiredParams, ...details})

    // return details;
    // if (token_order) {
    //     details.token_order = token_order
    // }
    // if (step) {
    //     details.step = step
    // }

    // if (expected_visit_date) {
    //     details.expected_visit_date = expected_visit_date
    // }
    // if (expected_visit_time) {
    //     details.expected_visit_time = expected_visit_time
    // }

    try {
        return await client.post("/dealer/order/job-sheet-add", {...requiredParams, ...details}, {headers: requiredHeader,});
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};

export const sheet_service_step = async ({details, is_image}) => {
    try {
        let headerImport = {...requiredHeader};
        let sendData = details;
        if (is_image) {
            headerImport = requiredHeaderImage
        }
        if (is_image) {
            sendData.append('device_type', requiredParams.device_type);
            sendData.append('istoken', requiredParams.istoken);
        } else {
            sendData = {...details, ...requiredParams};
        }
        return await client.post("/dealer/order/job-sheet-add", sendData, {headers: headerImport});
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message}; 
        return {error: error.message || error};
    }
};

let ajaxRequest = null;

export const get_job_sheet = async ({id, status, search_text, token_order, user_type, limit, offset}) => {
    const details = {
        login_user_id: id,
        login_user_type: user_type || "client"
    }
    let inputRequest = {...details, ...requiredParams};
    if (token_order) {
        inputRequest = {...inputRequest, token_order: token_order};
    } else {
        inputRequest = {...inputRequest, limit: limit, offset: offset};
    }
    if (status) {
        inputRequest = {...inputRequest, status: status};
    }
    if (search_text) {
        inputRequest = {...inputRequest, search_text: search_text};
    }

    try {
        if (ajaxRequest) {
            ajaxRequest.cancel();
        }
        ajaxRequest = axios.CancelToken.source();

        return await client.post("/dealer/order/job-sheet", inputRequest, {
            headers: requiredHeader,
            cancelToken: ajaxRequest.token
        }).catch(function (err) {

            if (axios.isCancel(err)) {
                // console.log('Previous request canceled, new request is send', err.message);
            } else {
                // handle error
            }
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};

export const get_job_sheet_dashboard = async ({id, user_type}) => {
    // console.log("Job sheet Dashboard")
    const inputRequest = {
        login_user_id: id,
        login_user_type: user_type || "dealer"
    }

    try {
        return await client.post("/dealer/front-jobsheet-dashboard", {...inputRequest, ...requiredParams}, {headers: requiredHeader,});
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};

export const jobsheet_update = async ({id, token_order, user_type, values}) => {
    const details = {
        login_user_id: id,
        login_user_type: user_type || "client"
    }
    let inputRequest = {...details, ...requiredParams, token_order: token_order};
    if (values) {
        inputRequest = {...inputRequest, values: {...values}};
    }
    // console.log(inputRequest);
    try {
        const {data} = await client.post("/dealer/order/service-update", inputRequest, {headers: requiredHeader,});
        // console.log(data);
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};

export const job_sheet_add = async ({inputData, user_id, User_type, token}) => {

    let details = {
        login_user_id: user_id,
        login_user_type: User_type || "dealer"
    }
    if (token) {
        details.token_order = token;
    }
    details = {...details, ...inputData}
    // console.log(details)
    try {
        const {data} = await client.post("/dealer/order/job-sheet-add", {...requiredParams, ...details}, {headers: requiredHeader,});
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};