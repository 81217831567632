import {isAppPaymentOn, razorpayApiKey} from "../../api/client";
import {appAddress, appTitle} from "../../api/constant";
import logo from "../asset/logo.webp";
import {get_data_by_url} from "../../api/general";


const loadRazorpayScript = () => {
    const script = document.createElement("script");
    script.src = `https://checkout.razorpay.com/v1/checkout.js`;
    document.body.appendChild(script);
};

const handleTransaction = async ({
                                     transaction,
                                     response,
                                     user,
                                     order,
                                     setReloadData,
                                     reloadData,
                                     showSuccessMessage,
                                     navigate
                                 }) => {
    if (transaction) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);

        let valuesTemp = {
            status: "success",
            source: "razorpay",
            ref: response?.razorpay_payment_id,
            remark: "",
        }
        if (response?.razorpay_order_id) valuesTemp.razorpay_order_id = response?.razorpay_order_id || "";
        if (response?.razorpay_payment_id) valuesTemp.razorpay_payment_id = response?.razorpay_payment_id || "";
        if (response?.razorpay_signature) valuesTemp.razorpay_payment_signature = response?.razorpay_signature || "";

        const inputData = {
            login_user_id: user?.id || "",
            login_user_type: user?.user_type || "",
            token: transaction?.token,
            values: valuesTemp
        }
        const res = await get_data_by_url("dealer/wallet-transaction-update", inputData).then((r) => {
            if (setReloadData) {
                setReloadData(!reloadData);
                if (showSuccessMessage) {
                    showSuccessMessage("Payment successfully completed.");
                }
            } else {
                navigate("/order-complete?order=" + order.token, {
                    state: {id: order?.order_parts[0]?.part?.token_brand},
                    replace: true,
                });
            }
        });
        // console.log("Wallet transaction", res)
        return res;
    }
}

export default function handlePaymentTransaction({
                                                     navigate,
                                                     transaction,
                                                     Razorpay,
                                                     setNotAvOpen,
                                                     paymentMode,
                                                     order,
                                                     user,
                                                     amount_total,
                                                     amount_currency,
                                                     setReloadData,
                                                     reloadData,
                                                     showSuccessMessage,
                                                     showErrorMessage
                                                 }) {

    if (!isAppPaymentOn) {
        setNotAvOpen(true);
        return;
    }
    loadRazorpayScript();

    // const order = await createOrder(params); //  Create order on your backend
    const amount = Math.round(amount_total * 100)
    // const amount = amount_total * 100; // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    const currency = amount_currency || "INR"
    const name = appTitle || "iService"
    const description = "Payment For " + (order?.title || "1234") + "";
    // const logoApp = appFooterLogo || logo || ""
    const logoApp = logo || ""

    const options = {
        key: razorpayApiKey || "rzp_live_JiqQPEKp9QCeKq", // Enter the Key ID generated from the Dashboard iservice
        // amount: amount || 0,
        amount: amount,
        currency: currency || "INR",
        name: name,
        description: description || "Test Transaction",
        image: logoApp,
        order_id: transaction?.razorpay_order_id || "", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: async function (response) {
            // console.log("payment response", response);
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
            // const payment = await capture_payment(response);
            // if (payment) {
            const res = handleTransaction({
                transaction: transaction,
                response: response,
                user: user,
                order: order,
                setReloadData: setReloadData,
                reloadData: reloadData,
                showSuccessMessage: showSuccessMessage,
                navigate: navigate
            });

            // }
        },
        modal: {
            confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
            // This function is executed when checkout modal is closed
            // There can be 3 reasons when this modal is closed.
            ondismiss: async (reason) => {
                const {
                    reason: paymentReason, field, step, code,
                } = reason && reason.error ? reason.error : {};
                // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
                if (reason === undefined) {
                    // console.log('cancelled');
                }
                // Reason 2 - When modal is auto closed because of time out
                else if (reason === 'timeout') {
                    // console.log('timedout');
                }
                // Reason 3 - When payment gets failed.
                else {
                    // console.log('failed');
                }
                if (setReloadData) {
                    // setReloadData(!reloadData);
                    if (showErrorMessage) {
                        showErrorMessage("Payment failed, Try again");
                    }
                } else {
                    navigate("/order-complete?order=" + order.token, {
                        state: {id: order?.order_parts[0]?.part?.token_brand},
                        replace: true,
                    });
                }
            },
        },
        prefill: {
            name: user?.name || " ",
            email: user?.email || " ",
            contact: user?.mobile || " ",
        },
        notes: {
            address: appAddress || "",
        },
        theme: {
            // color: "#3399cc",
            color: "#ef5d00",
            width: "500px !important",
        },
        // width: "500px",
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
        // console.log(response);
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        // showError("Payment failed, Try again");
        if (setReloadData) {
            // setReloadData(!reloadData);
            if (showErrorMessage) {
                showErrorMessage("Payment failed, Try again.");
            }
        } else {
            navigate("/order-complete?order=" + order.token, {
                state: {id: order?.order_parts[0]?.part?.token_brand},
                replace: true,
            });
        }

        return {
            error: true,
            status: "fail",
            message: "Payment failed, Try again",
            data: response
        }
    });

    rzp1.open();
}
