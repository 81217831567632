import {FaCopy, FaExternalLinkAlt, FaMapMarkerAlt, FaTimesCircle} from "react-icons/fa";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import {Col, Row} from "react-bootstrap";

export default function TrackingModel({tracking_url, data}) {
    const [copySuccessId, setCopySuccessId] = useState('');
    const [trackingUrl, setTrackingUrl] = useState(tracking_url);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const [maxWidthInfo, setMaxWidthInfo] = React.useState('sm');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCopySuccessId("");
    };
    console.log(data);
    const handleCloseInfo = () => {
        setOpenInfo(false);
    };

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(r => r)
        setCopySuccessId("copied!");
    };

    useEffect(() => {
        let url = data?.tracking_url;
        if (data?.tracking_id) {
            url = url.replaceAll("/" + (data?.tracking_id || "fsdfs"), "");
            url = url.replaceAll((data?.tracking_id || "fsdfs"), "");
        }
        setTrackingUrl(url);
    }, [data]);
    return <>
        <Fab variant="extended" size="small" color="primary" className={'px-4 mt-1 me-1'}
             onClick={() => setOpenInfo(true)}> <FaMapMarkerAlt
            sx={{mr: 3}}/>&nbsp; Track Order </Fab>

        <React.Fragment>
            {/*fullScreen*/}
            <Dialog open={openInfo} fullWidth={fullWidth} maxWidth={maxWidthInfo} onClose={handleCloseInfo}
                    aria-labelledby="dialog-track">
                <DialogTitle> {"Track Your shipment"}</DialogTitle>
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseInfo}
                        sx={{
                            position: 'absolute',
                            right: 2,
                            top: 2,
                            color: "red",
                        }}
                    >
                        <FaTimesCircle/>
                    </IconButton>
                    <DialogContentText>
                        <Row className={'my-3'}>
                            <Col><small>Tracking Partner</small></Col>
                            <Col><h6 className={'text-dark'}>{data?.tracking_partner}</h6></Col>
                        </Row>
                        <Row className={'my-3'}>
                            <Col><small>Tracking Id</small></Col>
                            <Col><h6 className={'text-dark'}>{data?.tracking_id} <FaCopy
                                className={"cursor-pointer" + (copySuccessId ? " text-success" : " text-primary")}
                                onClick={() => copyToClipboard(data?.tracking_id, "tracingId")}/>
                                <b><small className={'text-success'}>{copySuccessId}</small></b>
                            </h6></Col>
                        </Row>
                        <Row className={'my-3'}>
                            <Col><small>Tracking</small></Col>
                            <Col><h6 className={'text-dark'}>{trackingUrl} <FaExternalLinkAlt
                                className={"cursor-pointer text-primary"}
                                onClick={() => window.open(trackingUrl, "_blank")}/>
                            </h6></Col>
                        </Row>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenInfo(false)} variant="outlined">
                        Close
                    </Button>
                    <Button onClick={() => handleClickOpen(false)} variant="contained">
                        Track
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>


        {/*<Fab variant="extended" size="small" color="primary" className={'px-4 mt-1 me-1'}
             onClick={() => window.open(tracking_url, "_blank")}> <FaMapMarkerAlt
            sx={{mr: 3}}/>&nbsp; Track Order </Fab>*/}
        {/*<Fab variant="extended" size="small" color="primary" className={'px-4 mt-1 me-1'}
             onClick={handleClickOpen}> <FaMapMarkerAlt
            sx={{mr: 3}}/>&nbsp; Track Order </Fab>*/}

        <React.Fragment>
            {/*fullScreen*/}
            <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}
                    aria-labelledby="dialog-track">
                {/*<DialogTitle id="dialog-track"> {"Track Your shipment"}</DialogTitle>*/}
                <DialogContent>
                    {/*<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Modal title
                    </DialogTitle>*/}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 2,
                            top: 2,
                            color: "red",
                        }}
                    >
                        <FaTimesCircle/>
                    </IconButton>
                    {/*<AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <FaTimesCircle />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Sound
                            </Typography>
                            <Button autoFocus color="inherit" onClick={handleClose}>
                                save
                            </Button>
                        </Toolbar>
                    </AppBar>*/}
                    <iframe width={"100%"} height={"500"} src={tracking_url}
                            title="" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                    {/*<DialogContentText>
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    </>
}