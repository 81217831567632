import axios from "axios";

const isAppPaymentOn = true ;
const showAdBanners = true ;
const isAppTempDown = false ;

// const client = axios.create({baseURL: "http://localhost/L9/Laravel9-I-Service-V2/public/api"});
// const client = axios.create({baseURL: "https://testing.iserviceindia.in/panel/public/api"});
// const isAppOTP = true;

const client = axios.create({baseURL: "https://admin.iserviceindia.in/public/api"});
const isAppOTP = false;

const razorpayApiKey = "rzp_live_JiqQPEKp9QCeKq"; //Iservice
// const razorpayApiKey="rzp_live_E0P5QfFU2Fkc3h"; //Aimerse

export default client ;
export {razorpayApiKey, isAppTempDown, isAppOTP, isAppPaymentOn, showAdBanners}